<template>
	<div>
		<!--头部-->
		<appDetailHeader
			v-show="appGroup.id"
			:app-group="appGroup"
		>
		</appDetailHeader>

		<div style="display: flex;">
			<appVersions
				v-show="!showAppSetting"
				v-if="iosApp"
				:app-info="iosApp"
				style="flex: 1; margin-right: 10px;"
				@updateAppInfoSuccess="updateAppInfoSuccess"
			>
			</appVersions>
			<appVersions
				v-show="!showAppSetting"
				v-if="androidApp"
				:app-info="androidApp"
				style="flex: 1;"
				@updateAppInfoSuccess="updateAppInfoSuccess" 
			>
			</appVersions>
		</div>

		<appSetting
			v-if="showAppSetting"
			:app-group="appGroup"
		>
		</appSetting>
	</div>
</template>

<script type="text/ecmascript-6">
import * as AppResourceApi from '../../api/moudle/appResourceApi';
import { getUserTeam } from '../../mgr/userMgr';
import AppDetailHeader from './appDetailHeader.vue';
import AppVersions from './appVersions.vue';
import AppSetting from './appSetting.vue';

export default {
	components: {
		AppDetailHeader, AppVersions, AppSetting
	},
	data() {
		return {
			subTitleArr: ['', '', ''],
			appGroup: {},
			showAppSetting: false
		};
	},
	computed: {
		iosApp() {
			return this.appGroup?.ios;
		},
		androidApp() {
			return this.appGroup?.android;
		}
	},
	destroyed() {
		this.bus.$off('appSummary');
		this.bus.$off('appSetting');
	},
	created() {
		this.$nextTick(() => {
			// this.userteam = getUserTeam();
			this.getAppDetailData();
		});
		this.bus.$on('appSummary', () => {
			this.showAppSetting = false;
		});
		this.bus.$on('appSetting', () => {
			this.showAppSetting = true;
		});
	},
	methods: {
		getAppDetailData() {
			AppResourceApi.getAppGroupDetail(this.$route.params.appGroupId).then((res) => {
				console.log(res);
				this.appGroup = res.data;
				this.subTitleArr = [];
				this.subTitleArr.push(this.appGroup.bundleId);
				var {href} = this.$router.resolve({
					name: 'AppPreView',
					path: '/',
					params: { 'id': this.appGroup.shortUrl }
				});
				// this.subTitleArr.push(this.axios.defaults.baseURL + this.appInfo.shortUrl)
				href = href.substr(1);// 去掉第一个‘/’,否则会变成 baseurl//publish/shorturl
				this.subTitleArr.push(this.axios.defaults.baseURL + href);
				this.subTitleArr.push(this.appGroup.id);
				this.bus.$emit('appdetail', res.data.name);
			}, reject => {
				this.$message.error(reject);
			});
		},
		updateAppInfoSuccess() {
			this.getAppDetailData();
		}
	}
};
</script>

<style lang="scss">
  @import "../../common/scss/base";


</style>
